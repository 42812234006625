import {parseUrlToSearchParams} from "./UrlFormatter.Search";
import {emptyUrlParams, getSearchApiFromPageParams} from "./SearchHelper";
import {parseProductUrl} from "./UrlFormatter.Product";
import {parseGoToStore} from "./UrlFormatter.GoToStore";
import {getDefaultLang} from "../Store/Action/interactive";
import {getProductCacheKey} from "../Store/Action/cache.product";
import {splitRequestUrlToPathAndSearch} from "./UrlFormatter";
import {getSearchCacheKey} from "../Store/Action/cache.search";

// https://sitedirect.atlassian.net/wiki/spaces/SHOPIT/pages/106201092/Frontend+Log+DB+2023#log_page
export const PAGE_TYPE_UNKNOWN = 0
export const PAGE_TYPE_INDEX = 1
export const PAGE_TYPE_INFORMATION = 2
export const PAGE_TYPE_GO_TO_STORE = 3

export const PAGE_TYPE_PRODUCT = 10
export const PAGE_TYPE_COMPARE = 11

export const PAGE_TYPE_CATEGORY_SEARCH = 20
export const PAGE_TYPE_CATEGORY_SEARCH_WITH_FILTER = 21
export const PAGE_TYPE_COLLECTION = 30
export const PAGE_TYPE_COLLECTION_UNP = 31
export const PAGE_TYPE_COLLECTION_MANUFACTURER = 32
export const PAGE_TYPE_COLLECTION_FAMILY = 33
export const PAGE_TYPE_COLLECTION_BRANDING = 34
export const PAGE_TYPE_COLLECTION_VENDOR = 35
export const PAGE_TYPE_COLLECTION_FREETEXT = 36

export const PAGE_TYPE_USER_SHOW = 40

export const ACTION_TYPE_SAYT = "sayt"
export const ACTION_TYPE_SAYT_ERROR = "sayt_error"
export const ACTION_TYPE_SEARCH = "search"
export const ACTION_TYPE_SEARCH_ERROR = "search_error"
export const ACTION_TYPE_CATEGORY_STRUCTURE = "category_structure"
export const ACTION_TYPE_CATEGORY_STRUCTURE_SELECTED = "category_structure_selected"
export const ACTION_TYPE_CATEGORY_STRUCTURE_PARENT = "category_structure_parent"
export const ACTION_TYPE_CATEGORY_SEARCH = "category_search"
export const ACTION_TYPE_CATEGORY_STRUCTURE_IDS = "category_structure_ids"
export const ACTION_TYPE_PRODUCT_OFFER = "product_offer"
export const ACTION_TYPE_MERGED_PRODUCT = "merged_product"
export const ACTION_TYPE_PRODUCT = "product"
export const ACTION_TYPE_PRODUCT_IDS = "product_ids"

export const ACTION_TYPE_LOAD_USER = "user_load"
export const ACTION_TYPE_SET_PRODUCT_STATUS = "set_product_status"

export const ACTION_TYPE_LOGIN = "login"
export const ACTION_TYPE_LOGOUT = "logout"
export const ACTION_TYPE_RECOVER = "recover"
export const ACTION_TYPE_REGISTER = "register"

export const PAGE_NOT_FOUND_TYPE_PRODUCT = 1
export const PAGE_NOT_FOUND_TYPE_SEARCH_NO_RESPONSE = 2
export const PAGE_NOT_FOUND_TYPE_SEARCH_EMPTY = 3


function testFilters(filters: string, key: string): string | undefined {
    if (filters.indexOf(',') !== -1) return undefined;
    const parts = filters.split(':', 2);
    if (parts.length != 2) return undefined;
    if (parts[0] == key) return parts[1];
    return undefined;
}

export function getRoute(url: string): [string, string | undefined, number | undefined, number | undefined] {

    const urlSeparator = url.indexOf('?');
    let [pathname, search] = urlSeparator !== -1 ? [url.substring(0, urlSeparator), url.substring(urlSeparator)] : [url, undefined]

    // try to extract search
    const urlPageParams = parseUrlToSearchParams(pathname, search || '');
    if (!emptyUrlParams(urlPageParams.bridgeParams)) {
        if (urlPageParams.bridgeParams.categoryId) {

            if (urlPageParams.bridgeParams.filters) {
                // todo: send filters
                return ['/search', getSearchApiFromPageParams(urlPageParams), PAGE_TYPE_CATEGORY_SEARCH_WITH_FILTER, urlPageParams.bridgeParams.categoryId]
            } else {
                return ['/search', getSearchApiFromPageParams(urlPageParams), PAGE_TYPE_CATEGORY_SEARCH, urlPageParams.bridgeParams.categoryId]
            }

        } else if (urlPageParams.bridgeParams.filters) {
            const unpFilter = testFilters(urlPageParams.bridgeParams.filters, "s24")
            if (unpFilter) {
                return ['/search', getSearchApiFromPageParams(urlPageParams), PAGE_TYPE_COLLECTION_UNP, parseInt(unpFilter)]
            }

            // todo: family came with manufacturer, keep that in mind
            const familyFilter = testFilters(urlPageParams.bridgeParams.filters, "s11")
            if (familyFilter) {
                return ['/search', getSearchApiFromPageParams(urlPageParams), PAGE_TYPE_COLLECTION_FAMILY, parseInt(familyFilter)]
            }

            const manufFilter = testFilters(urlPageParams.bridgeParams.filters, "s3")
            if (manufFilter) {
                return ['/search', getSearchApiFromPageParams(urlPageParams), PAGE_TYPE_COLLECTION_MANUFACTURER, parseInt(manufFilter)]
            }

            const brandFilter = testFilters(urlPageParams.bridgeParams.filters, "s4")
            if (brandFilter) {
                return ['/search', getSearchApiFromPageParams(urlPageParams), PAGE_TYPE_COLLECTION_BRANDING, parseInt(brandFilter)]
            }
        } else if (urlPageParams.bridgeParams.text) {
            // todo: here need to send text, not numeric id
            return ['/search', getSearchApiFromPageParams(urlPageParams), PAGE_TYPE_COLLECTION_FREETEXT, undefined]
        }

        return ['/search', getSearchApiFromPageParams(urlPageParams), undefined, undefined]
    }

    const productId = parseProductUrl(pathname, search || '');
    if (productId[0]) {
        return ['/product', productId.toString(), PAGE_TYPE_PRODUCT, productId[0]]     // TODO: change this 1- id
    }

    if (pathname == "/") {
        return ['/', undefined, PAGE_TYPE_INDEX, undefined]
    }

    if (pathname == "/external" || pathname == "/go-to-store") {
        if (search) {
            const potProductId = parseGoToStore(search)
            if (potProductId) {
                return ['/go-to-store', potProductId.toString(), PAGE_TYPE_GO_TO_STORE, potProductId]
            }
        }
    }

    if (pathname == "/lagg-till-din-butik" ||
        pathname == "/add-your-store" ||
        pathname == "/om" ||
        pathname == "/about" ||
        pathname == "/kontakt" ||
        pathname == "/contact" ||
        pathname == "/sa-funkar-det" ||
        pathname == "/how-it-works" ||
        pathname == "/ansvarsbegransning" ||
        pathname == "/liability" ||
        pathname == "/our-unique-technology" ||
        pathname == "/var-unika-teknologi" ||
        pathname == "/anvandarpolicy" ||
        pathname == "/personal-policy" ||
        pathname == "/cookie-policy" ||
        pathname == "/landing-ehandel-se") {
        return [pathname, undefined, PAGE_TYPE_INFORMATION, undefined]
    }

    return ['unknown', undefined, undefined, undefined]
}


export function getPageNotFoundTypeId(url: string, state: IAppState | Partial<IAppState>): number | undefined {
    let pageNotFoundTypeId: number | undefined = undefined

    const [route, routeParams, type, itemId] = getRoute(url)
    if (route === '/product' || route === '/search') {
        const lang = state && state.config && state.config.lang ? state.config.lang : getDefaultLang()
        const debug = state && state.config ? state.config.debug : false
        if (route === '/product') {
            if (itemId) {
                const productKey = getProductCacheKey(itemId, lang, debug)
                if (state
                    && state.cache
                    && state.cache.product
                    && state.cache.product[productKey]
                    && state.cache.product[productKey].cacheState == CacheState.NotFound) {
                    pageNotFoundTypeId = PAGE_NOT_FOUND_TYPE_PRODUCT
                }
            }
        } else {
            const [pathname, search] = splitRequestUrlToPathAndSearch(url)
            const searchParams = parseUrlToSearchParams(pathname, search)
            if (searchParams && !emptyUrlParams(searchParams.bridgeParams)) {
                const urlRequestKey = getSearchApiFromPageParams(searchParams)
                const searchKey = getSearchCacheKey(urlRequestKey, lang, debug)
                const cache = state.cache?.search[searchKey]
                if (cache) {
                    if (cache.cacheState == CacheState.ServerNotResponse) {
                        pageNotFoundTypeId = PAGE_NOT_FOUND_TYPE_SEARCH_NO_RESPONSE
                    } else if (cache.cacheState == CacheState.Cache) {
                        if (!cache.object || !cache.object.items || !cache.object.items.pagination) {
                            pageNotFoundTypeId = PAGE_NOT_FOUND_TYPE_SEARCH_EMPTY
                        }
                    }
                }
            }
        }
    }

    return pageNotFoundTypeId
}