import React from "react";
import {getAvatarImageUrl, getCoverImageUrl} from "../../Helpers/ImageHelper";

export default function UserHeader(props: { user: IUser | undefined }) {

    const imageOnErrorHandler = (e: React.SyntheticEvent) => {
        (e.currentTarget as any).src = getAvatarImageUrl(undefined, '');
    }

    return <div>
        <div className="bg-gray-400" style={{
            background: `url('${getCoverImageUrl(props.user?.id)}') no-repeat center center, linear-gradient(to bottom, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.20) 10%, rgba(0, 0, 0, 0.35) 100%)`,
            backgroundSize: 'cover'
        }}>
            <div className="container mx-auto h-[155px]">

            </div>
        </div>
        <div className="container mx-auto py-4">
            <img src={getAvatarImageUrl(props.user?.id)}
                 className="w-[145px] h-[145px] inline-block rounded-full border-[5px] border-solid border-white -mt-20 mr-4"
                 onError={imageOnErrorHandler}
                 alt={props.user?.firstName||""}/>

            <span className="text-lg font-bold">
                    {props.user ?
                        props.user.firstName + ' ' + props.user.lastName :
                        'Unknown user'
                    }
                </span>
        </div>
    </div>
}
