import {
    CONFIG_LANGUAGE_SET,
    CONFIG_LOADING_LOCALIZATION,
    CONFIG_RESPONSE_LOCALIZATION_COUNTRIES,
    CONFIG_RESPONSE_LOCALIZATION_CURRENCIES,
    CONFIG_RESPONSE_LOCALIZATION_LANGUAGES,
    ConfigLanguageSetAction,
    RequestLocalizationAction,
    ResponseLocalizationCountriesAction,
    ResponseLocalizationCurrenciesAction,
    ResponseLocalizationLanguagesAction
} from "../Action/config.localization";
import {
    CONFIG_DEBUG_TOGGLE,
    DebugActions
} from "../Action/config.debug";

export const serverSettingsInitialState: IAppConfigState = {
    countries: [],
    currencies: [],
    languages: [],
    countriesState: CacheState.Unknown,
    currenciesState: CacheState.Unknown,
    languagesState: CacheState.Unknown,
    domainImageUrl: undefined,
    avatarImageUrl: undefined,
    coverImageUrl: undefined,
    productImageUrl: undefined,

    debug: false
};

const configReducer = (state: IAppConfigState = serverSettingsInitialState,
                       action: RequestLocalizationAction
                           | ResponseLocalizationLanguagesAction
                           | ResponseLocalizationCurrenciesAction
                           | ResponseLocalizationCountriesAction
                           | ConfigLanguageSetAction
                           | DebugActions): IAppConfigState => {
    switch (action.type) {
        case CONFIG_LANGUAGE_SET:
            const localizationChangeAction = action as ConfigLanguageSetAction;
            return {
                ...state,
                lang: localizationChangeAction.lang,
            };

        case CONFIG_LOADING_LOCALIZATION: {
            const dataAction = action as RequestLocalizationAction;
            if (dataAction.localizationType == "languages" && (state.languagesState == CacheState.NotFound || state.languagesState == CacheState.Unknown || state.languagesState == CacheState.ServerNotResponse)) {
                return {
                    ...state,
                    languagesState: CacheState.Loading
                };
            } else if (dataAction.localizationType == "countries" && (state.countriesState == CacheState.NotFound || state.countriesState == CacheState.Unknown || state.countriesState == CacheState.ServerNotResponse)) {
                return {
                    ...state,
                    countriesState: CacheState.Loading
                };
            } else if (dataAction.localizationType == "currencies" && (state.currenciesState == CacheState.NotFound || state.currenciesState == CacheState.Unknown || state.currenciesState == CacheState.ServerNotResponse)) {
                return {
                    ...state,
                    currenciesState: CacheState.Loading
                };
            }
        }
        case CONFIG_RESPONSE_LOCALIZATION_LANGUAGES: {
            const dataAction = action as ResponseLocalizationLanguagesAction;
            return {
                ...state,
                languages: dataAction.languages
            };
        }
        case CONFIG_RESPONSE_LOCALIZATION_CURRENCIES: {
            const dataAction = action as ResponseLocalizationCurrenciesAction;
            return {
                ...state,
                currencies: dataAction.currencies
            };
        }
        case CONFIG_RESPONSE_LOCALIZATION_COUNTRIES: {
            const dataAction = action as ResponseLocalizationCountriesAction;
            return {
                ...state,
                countries: dataAction.countries
            };
        }
        case CONFIG_DEBUG_TOGGLE:
            const debugAction = action as DebugActions;
            return {
                ...state,
                debug: !state.debug,
            };
    }
    return state;
};

export default configReducer;