import latinize from "./LatinizeHelper";

export function splitRequestUrlToPathAndSearch(url: string) {
    const separatorIndex = url.indexOf('?')
    if (separatorIndex !== -1) {
        return [url.substring(0, separatorIndex), url.substring(separatorIndex)]
    }
    return [url, ""]
}

export function latinizeAndSimplify(name: string | undefined, softLimit: number = -1, hardLimit: number = -1): string | undefined {
    if (name) {
        name = latinize(name)
        if (softLimit > 0 && name.length > softLimit) {
            const pos = name.indexOf('-', softLimit);
            if (pos !== -1) {
                name = name.substring(0, pos);
            }
            if (name.length > hardLimit) {
                name = name.substring(0, hardLimit);
            }
        }
    }
    return name && name.length > 0 ? name : "-"
}

export function absoluteLink(relativeLink: string): string {
    if (relativeLink.startsWith("http")) return relativeLink;
    if (relativeLink.startsWith("/")) return "https://shopit.se" + relativeLink;
    return "https://shopit.se/" + relativeLink;
}