import {latinizeAndSimplify} from "./UrlFormatter";

/**
 * @see parseProductUrl
 */
export function buildProductUrl(productIdOrGtinId: number, productName: string | undefined, categoryName: string | undefined): [string, boolean] {
    const productNormalName = latinizeAndSimplify(productName, 120, 160)
    const categoryNormalName = latinizeAndSimplify(categoryName)

    // actual url formal
    const actualLink = categoryNormalName != '-' && productNormalName != '-';
    return [`/${categoryNormalName}/${productNormalName}-p${productIdOrGtinId}`, actualLink]

    // previous format. TODO: remove for release
    // if(productName){
    //     return `/product/${productNormalName}-p${productIdOrGtinId}`
    // }
    //
    // previous format. TODO: remove for release
    // return `/product/${productIdOrGtinId}`;
}

/**
 * @see buildProductUrl
 */
export function parseProductUrl(pathname: string, search: string): [number | undefined, boolean] {

    // actual url formal
    const actualMatch = pathname.match(/\/[\w-]+\/[\w-]+[\/-]p(?<product_id>\d+)$/);
    if (actualMatch && actualMatch.groups) {
        return [parseInt(actualMatch.groups.product_id), true];
    }

    // previous format. TODO: remove for release
    // const legacyMatch = pathname.match(/(\/\w+)?\/[\w-]+p(?<product_id>\d+)$/);
    // if (legacyMatch && legacyMatch.groups) {
    //     return [parseInt(legacyMatch.groups.product_id), false];
    // }
    //
    // // previous format. TODO: remove for release
    // const simpleMatch = pathname.match(/\/product\/(?<product_id>\d+)$/);
    // if (simpleMatch && simpleMatch.groups) {
    //     return [parseInt(simpleMatch.groups.product_id), false];
    // }

    return [undefined, false]
}
